<template>
  <div class="train project">
    <!-- <div class="breadcrumb">
      <span class="name">当前位置：</span>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>培训项目</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->
    <div class="content main-width">

      <div class="select-groups">
        <div class="form_input">
          <div class="name">地点</div>
          <selectCategory ref="address" :changeonselect="true" dictValue="p24" :clearable="false" codeType="provinceid" :codeLevel="3" @setMValue="getAdress"
            selectType="cascade_type" width="174px" placeholder="请选择城市" class="input" />
        </div>
        <div class="form_input mr60">
          <div class="name">残疾类别</div>
          <selectCategory code-type="disabled_type" :clearable="false" :codeLevel='2' @setMValue="getDisable" selectType="disabled_type" width="174px" placeholder="全部"
            class="input" @change="getList('search')" :defaultValue="[queryParams.disableType, queryParams.disableLevel]" />
        </div>
        <div class="form_input mr60" v-show="isMore">
          <div class="name">报名状态</div>
          <el-select v-model="queryParams.registrationStatus" placeholder="请选择报名状态" style="width: 174px" @change="getList('search')">
            <el-option label="已经开始报名" key="1" value="1" />
            <el-option label="未开始报名" key="2" value="2" />
          </el-select>
        </div>
        <div class="form_input mr60" v-show="isMore">
          <div class="name">培训方式</div>

          <MulSelects code="train_ing" width="174px" v-model="queryParams.trainIng" placeholder="全部" class="input" @change="getList('search')" />
        </div>
        <div class="form_input mr60" v-show="isMore">
          <div class="name">课程类别</div>
          <MulSelects code="TRAIN_TYPE" width="174px" v-model="queryParams.trainType" placeholder="全部" class="input" @change="getList('search')" />
        </div>
        <div class="form_input" v-show="isMore">
          <div class="name">热度排序</div>
          <el-select v-model="queryParams.heatOrder" style="width: 174px" placeholder="全部" @change="getList('search')">
            <el-option label="由高到低" value="1" />
            <el-option label="由低到高" value="2" />
          </el-select>
        </div>
        <div class="more" style="cursor: pointer;" :class="!isMore?'isMore':''" @click="isMore = !isMore">
          {{isMore?'收起更多筛选':'查看更多筛选'}}
        </div>
        <div class="form_input" style="margin-right: 0;">
          <el-button type="primary" plain round @click="resets"><img src="@/static/project/reset.png" alt="" />重置条件
          </el-button>
        </div>
      </div>
      <div class="list" v-loading="isLoading" element-loading-text="数据加载中...">
        <ul class="ul" v-if="isExhibition == '0'">
          <li>
            <template v-for="item in courseList">
              <trainItem :data="item" />
            </template>
          </li>
          <li style="margin-top: 48px">
            <h3 class="title" v-if="courseListHot.length > 0">以下为户籍要求为本地的热门培训项目</h3>
            <template v-for="item in courseListHot">
              <trainItem :data="item" />
            </template>
          </li>
        </ul>
        <ul class="ul" v-else>
          <li class="empty">
            <img src="@/static/project/empty.png" alt="">
            <p>
              当前无可报名的项目，您可查看当前地区培训计划，关注即将开办的培训项目！
            </p>
            <p v-if="isExhibition != '2'">
              以下为其他地区热门培训项目
            </p>
          </li>
          <li>
            <h3 class="title">热门培训</h3>
            <template v-for="item in courseListHot">
              <trainItem :data="item" />
            </template>
          </li>
        </ul>
      </div>
    </div>
    <el-pagination background layout="prev, pager, next, total, jumper" @current-change="changePage" :current-page="queryParams.pageNum" :page-size="queryParams.pageSize"
      :total="atotal" />
  </div>
</template>
<script  >
import trainItem from '@/components/public/trainItem';
import selectCategory from '@/components/selectComponents/selectCategory';
import MulSelects from 'components/selectComponents/MulSelects';
export default {
  components: {
    trainItem,
    selectCategory,
    MulSelects
  },
  data() {
    return {
      show: false,
      isLoading: false,
      isMore: false,
      atotal: 0,
      dataStatus: '',
      courseListHot: [],
      courseList: [],
      isExhibition: [],
      queryParams: {
        addressCityId: '',
        addressProvinceId: 'p05',
        addressThreeCityId: '',
        disableLevel: '',
        disableType: '',
        heatOrder: '',
        assessOrder: '',
        registrationStatus: '',
        trainIng: '',
        trainType: '',
        pageNum: 1,
        pageSize: 12
      }
    };
  },
  methods: {
    getList(type) {
      this.isLoading = true;
      this.courseListHot = [];
      this.courseList = [];
      this.$api
        .queryCourseList(this.queryParams)
        .then(res => {
          this.dataStatus = type;
          const { data, success, msg } = res.data;
          this.isExhibition = data.isExhibition;
          if (!success && msg) return this.$message.error(msg);
          const { records, total } = data.courseListOutDTOIPage;
          this.atotal = total;
          if (data.isExhibition != '0') {
            this.courseListHot = records;
          } else {
            records.forEach((n, i) => {
              if (n.isArea === '0') {
                this.courseList.push(n);
              } else if (n.isArea === '1') {
                this.courseListHot.push(n);
              }
            });
          }

          this.isLoading = false;
        })
        .catch(err => {
          this.dataStatus = 'error';
          this.isLoading = false;
        });
    },
    getDisable(val) {
      this.queryParams = Object.assign(this.queryParams, {
        disableType: val ? val[0] : '',
        disableLevel: val ? val[1] : ''
      });
    },
    resets() {
       console.log(this.queryParams.trainIng,'88888')
      for (let key in this.queryParams) {
        if (typeof this.queryParams[key] === 'string') {
          this.queryParams[key] = '';
        }
      }
      console.log(this.queryParams.trainIng,'9999999')
      this.$refs.address.value = '';
      this.getList('search');
    },
    getAdress(val) {
      this.queryParams.addressProvinceId = val ? val[0] : '';
      this.queryParams.addressCityId = val && val[1] ? val[1] : '';
      this.getList('search');
    },
    changePage(val) {
      this.queryParams.pageNum = val;
      this.getList('search');
    }
  },
  mounted() {
    this.getList('init');
    this.$emit('goRouter', '/train/');
  }
};
</script>
<style lang="less" scoped>
.project {
  padding-bottom: 48px;
  .select-groups {
    display: flex;
    flex-wrap: wrap;
    padding: 0 18px 18px;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #f2f2f2;
    margin-top: 0 !important;
    margin-bottom: 18px;
    .form_input {
      display: flex;
      align-items: center;
      margin-right: 24px;
      margin-top: 18px;

      .search-input {
        .el-input__inner {
          border-radius: 30px;

          &::placeholder {
            font-size: 16px;
            color: #99a09d;
            line-height: 24px;
          }
        }

        .el-link__inner {
          font-size: 16px;
          color: #56c1ab;
          line-height: 24px;
        }
      }

      .el-input__inner {
        color: #00120a;
      }

      .el-input__inner::placeholder {
        color: #00120a;
      }

      .name {
        font-size: 16px;
        font-weight: 400;
        color: #66716c;
        margin-right: 12px;
        display: inline-block;
        text-align-last: justify;
        text-align: justify;
        text-justify: distribute;
        vertical-align: middle;
      }

      .el-button {
        // width: 125px;
      }

      .input {
        flex: 1;
      }
    }

    .form_input:last-child {
      margin-right: 0;
    }
  }
  .select-groups {
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #f2f2f2;
    margin-top: 30px;
    margin-bottom: 12px;
    position: relative;

    //&::before {
    //  display: block;
    //  content: '';
    //  width: 1px;
    //  height: 108px;
    //  background: rgba(0, 18, 10, 0.05);
    //  position: absolute;
    //  right: 175px;
    //  top: 50%;
    //  transform: translateY(-50%);
    //}
    .more {
      display: flex;
      align-items: flex-end;
      font-size: 16px;
      color: #66716c;
      line-height: 24px;
      &.isMore {
        padding-bottom: 5px;
      }
    }
  }

  .content {
    width: 1200px;
    margin: 0 auto;

    .list {
      min-height: 320px;

      .ul {
        .title {
          display: flex;
          align-items: center;
          font-size: 20px;
          font-weight: bold;
          font-family: MicrosoftYaHeiSemibold;
          color: #00120a;
          line-height: 28px;
          height: 64px;
          background: #ffffff;
          border-radius: 2px 2px 0px 0px;
          border: 1px solid rgba(0, 18, 10, 0.05);
          margin-bottom: 12px;
          padding-left: 18px;
        }

        .empty {
          padding: 19px 0 36px 0;
          background: white;
          text-align: center;
          margin-bottom: 12px;

          img {
            margin-bottom: 18px;
          }

          p {
            font-size: 16px;
            color: #66716c;
            line-height: 24px;
          }

          .el-button {
            margin-top: 18px;
          }
        }
      }
    }
  }

  .form_input {
    .name {
      width: 65px;
    }

    .el-button {
      position: absolute;
      top: 18px;
      right: 18px;
      width: 125px;
      font-size: 16px;

      img {
        margin-right: 3px;
      }
    }

    :deep(.is-plain) {
      background: transparent;
      border: 1px solid rgba(0, 18, 10, 0.1);

      span {
        color: #56c1ab !important;
      }
    }
  }
  .fixed {
    position: fixed;
    top: 50%;
    right: 50px;
    transform: translateY(-50%);
    width: 74px;
    height: 197px;
    background: url('/static/images/project/fixed.png') center top no-repeat;
    background-size: auto 100%;
    font-size: 18px;
    font-family: MicrosoftYaHeiSemibold;
    color: #0077bb;
    line-height: 28px;
    cursor: pointer;
    text-align: center;
    .file {
      position: absolute;
      top: -35px;
      left: 50%;
      transform: translateX(-50%);
    }
    p {
      padding-top: 20px;
      font-weight: bold;
      letter-spacing: 1px;
      line-height: 28px;
    }
    .return {
    }
  }
}
</style>
